<template>
  <div class="home-bottom">
    <div class="bottom-container">
      <div class="link-box">
        <div class="left-info">
          <div class="item" v-for="(item, idx) in infoList" :key="'info'+idx">
            <i class="icon" :class="`icon${idx + 1}`"></i>
            <div class="info">
              <p class="name">{{item.name}}</p>
              <p class="value">{{item.value}}</p>
            </div>
          </div>
        </div>
        <div class="right-link">
          <p class="title2">{{$t('home.lPolicy')}}</p>
          <p class="name" @click="clickTos1()">{{$t('tos.t1')}}</p>
          <p class="name" @click="clickTos2()">{{$t('tos.t2')}}</p>
          <p class="name" @click="clickTos3()">{{$t('tos.t3')}}</p>
          <p class="name" @click="clickTos4()">{{$t('tos.t4')}}</p>
          <p class="title2 title3">{{$t('operation.qq2')}}</p>
          <img src="@assets/images/ai/code.png">
        </div>
      </div>
      <div class="copyright">{{$t('home.lRights2')}}<span class="red">{{$t('home.lRights3')}}</span></div>
    </div>
    <miya-tos ref="miyaTos" :showBtn="false"></miya-tos>
    <miya-tos2 ref="miyaTos2" :showBtn="false"></miya-tos2>
    <miya-tos3 ref="miyaTos3" :showBtn="false"></miya-tos3>
    <miya-tos4 ref="miyaTos4" :showBtn="false"></miya-tos4>
  </div>
</template>
<script>
import miyaTos from '../login/tos.vue'
import miyaTos2 from '../login/tos2.vue'
import miyaTos3 from '../login/tos3.vue'
import miyaTos4 from '../login/tos4.vue'
export default {
  data () {
    return {
      infoList: [],
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.infoList = [
        {name: this.$t('home.lTel'), value: '+1 7604621474'},
        {name: this.$t('home.lEmail'), value: 'amandamiller@miyaip.com'},
        {name: this.$t('home.lTime'), value: '9:00-18:00 PST'},
        {name: this.$t('home.lAddress'), value: '4479 S Xavier St Denver CO 80236'},
      ]
    },
    clickTos1 () {
      this.$refs.miyaTos.show()
    },
    clickTos2 () {
      this.$refs.miyaTos2.show()
    },
    clickTos3 () {
      this.$refs.miyaTos3.show()
    },
    clickTos4 () {
      this.$refs.miyaTos4.show()
    },
  },
  watch: {
    language () {
      this.getData()
    },
  },
  components: {miyaTos, miyaTos2,miyaTos3,miyaTos4}
}
</script>
<style scoped lang="stylus">
.home-bottom
  background-color $c8
  padding 50px 0 30px 0
  .bottom-container
    width 1200px
    margin 0 auto
    .link-box
      padding-bottom 20px
      display flex
      .left-info
        width 28%
        margin-right 18%
        .item
          display flex
          .icon1
            background url('../../assets/images/home/l1.png')
          .icon2
            background url('../../assets/images/home/l2.png')
          .icon3
            background url('../../assets/images/home/l3.png')
          .icon4
            background url('../../assets/images/home/l4.png')
          .icon
            width 40px
            height 40px
            background-size 100% 100%
            margin-right 16px
          .info
            flex 1
            margin-bottom 36px
            .name
              font-size $f16
              color $c7
              line-height 22px
              margin-bottom 4px
            .value
              font-size $f16
              color rgba(255,255,255,0.7)
              line-height 26px
      .right-link
        flex 1
        .title2
          color $c7
          fontWeight()
          font-size $f20
          margin-bottom 32px
        .name
          font-size $f16
          line-height 22px
          color rgba(255,255,255,0.6)
          cursor pointer
          margin-bottom 24px
          &:hover
            color $c1
        .title3
          margin-top 36px
          margin-bottom 16px
    .copyright
      font-size $f14
      text-align center
      margin 24px 0 0 0
      color $c7
      position relative
      z-index 5
      .red
        color $c7
@media screen and (max-width: 800px) 
  .home-bottom
    padding 2rem 0 1rem 0
    .bottom-container
      width 100%
      .link-box
        display block
        .left-info
          width 100%
          margin-right 0
          padding 0 1rem
          .item
            .info
              .name
                font-size 0.8rem
                line-height 0.8rem
              .value
                font-size 0.85rem
                line-height 1.2rem
        .right-link
          padding 0 1rem
          .title2
            font-size 0.85rem
          .name
            font-size 0.8rem
      .copyright
        padding 0 1rem
        font-size 0.75rem
        line-height 1rem
</style>