<template>
  <div class="miya-login-tos">
    <el-dialog :title="$t('tos.t1')" top="10vh" :visible.sync="dialogTos" :close-on-click-modal="false" :close-on-press-escape="false" :width="pageWidth < 800 ? '86%':'40%'">
      <div class="textBox">
        <p class="text1">{{$t('tos.c11')}}</p><br/>
        <p class="text1">{{$t('tos.c12')}}</p><br/>
        <p class="title1">{{$t('tos.t1_1')}}</p>
        <p class="text1">{{$t('tos.c1_11')}}</p><br/>
        <p class="title1">{{$t('tos.t1_2')}}</p>
        <p class="text1">{{$t('tos.c1_21')}}</p><br/>
        <p class="text1">{{$t('tos.c1_22')}}</p><br/>
        <p class="text1">{{$t('tos.c1_23')}}</p><br/>
        <p class="title1">{{$t('tos.t1_3')}}</p>
        <p class="text1">{{$t('tos.c1_31')}}</p><br/>
        <p class="text1">{{$t('tos.c1_32')}}</p><br/>
        <p class="text1">{{$t('tos.c1_33')}}</p><br/>
        <p class="title1">{{$t('tos.t1_4')}}</p>
        <p class="text1">{{$t('tos.c1_41')}}</p><br/>
        <p class="text1">{{$t('tos.c1_42')}}</p><br/>
        <p class="text1">{{$t('tos.c1_43')}}</p><br/>
        <p class="title1">{{$t('tos.t1_5')}}</p>
        <p class="text1">{{$t('tos.c1_51')}}</p><br/>
        <p class="text1">{{$t('tos.c1_52')}}</p><br/>
        <p class="text1">{{$t('tos.c1_53')}}</p><br/>
        <p class="title1">{{$t('tos.t1_6')}}</p>
        <p class="text1">{{$t('tos.c1_61')}}</p><br/>
        <p class="text1">{{$t('tos.c1_62')}}</p><br/>
        <p class="title1">{{$t('tos.t1_7')}}</p>
        <p class="text1">{{$t('tos.c1_71')}}</p><br/>
        <p class="text1">{{$t('tos.c1_72')}}</p><br/>
        <p class="title1">{{$t('tos.t1_8')}}</p>
        <p class="text1">{{$t('tos.c1_81')}}</p><br/>
        <p class="title1">{{$t('tos.t1_9')}}</p>
        <p class="text1">{{$t('tos.c1_91')}}</p><br/>
      </div>
      <div class="dialog-footer btns" v-show="showBtn">
        <el-button class="miyaBtnInfo" type="info" style="width:88px;" size="medium" plain @click.stop="dialogTos = false">{{$t('login.cancel')}}</el-button>
        <el-button class="ok" type="info" style="width:88px;" size="medium" @click.stop="clickOK()">{{$t('login.agree')}}</el-button>
      </div>      
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogTos: false,
    }
  },
  props: {
    showBtn: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    show () {
      this.dialogTos = true
    },
    close () {
      this.dialogTos = false
    },
    clickOK () {
      this.dialogTos = false
      this.$emit('clickOK')
    },
  }
}
</script>
<style scoped lang="stylus">
.miya-login-tos
  .textBox
    height 60vh
    overflow-y auto
    .title1
      font-size $f16
      color $c1
      line-height 32px
      font-weight 600
    .text1
      color $c2
      font-size $f14
      line-height 20px
  .btns
    margin-top 24px
    display flex
    justify-content center
    .ok, .miyaBtnInfo
      border-radius 50px
</style>
<style lang="stylus">
.miya-login-tos
  .el-dialog__body
    padding 24px 12px 24px 24px
</style>