<template>
  <div class="miya-login-tos">
    <el-dialog
      :title="$t('tos.t4')"
      top="10vh"
      :visible.sync="dialogTos"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :width="pageWidth < 800 ? '86%' : '40%'"
    >
      <div class="textBox">
        <p class="title1">{{ $t("tos.t4_1") }}</p>
        <p class="text1">{{ $t("tos.t4_2") }}</p>
        <br />
        <p class="text1">{{ $t("tos.t4_3") }}</p>
        <br />
        <p class="text1">{{ $t("tos.t4_4") }}</p>
        <br />
        <p class="text1">{{ $t("tos.t4_5") }}</p>
        <br />
        <p class="text1">{{ $t("tos.t4_6") }}</p>
        <br />
        <p class="text1">{{ $t("tos.t4_7") }}</p>
        <br />
        <p class="text1">{{ $t("tos.t4_8") }}</p>
      </div>
      <div class="dialog-footer btns" v-show="showBtn">
        <el-button
          class="miyaBtnInfo"
          type="info"
          style="width: 88px"
          size="medium"
          plain
          @click.stop="dialogTos = false"
          >{{ $t("login.cancel") }}</el-button
        >
        <el-button
          class="ok"
          type="info"
          style="width: 88px"
          size="medium"
          @click.stop="clickOK()"
          >{{ $t("login.agree") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogTos: false,
    };
  },
  props: {
    showBtn: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    show() {
      this.dialogTos = true;
    },
    close() {
      this.dialogTos = false;
    },
    clickOK() {
      this.dialogTos = false;
      this.$emit("clickOK");
    },
  },
};
</script>
<style scoped lang="stylus">
.miya-login-tos
  .textBox
    height 60vh
    overflow-y auto
    .title1
      font-size $f16
      color $c1
      line-height 32px
      font-weight 600
    .text1
      color $c2
      font-size $f14
      line-height 20px
  .btns
    margin-top 24px
    display flex
    justify-content center
    .ok, .miyaBtnInfo
      border-radius 50px
</style>
<style lang="stylus">
.miya-login-tos
  .el-dialog__header
    padding 20px 40px
  .el-dialog__body
    padding 24px 12px 24px 24px
</style>
