<template>
  <div class="home-list">
    <div class="list-container">
      <div class="item" v-for="(item, idx) in dataList" :key="'item'+idx" @click="clickItem(item)">
        <p class="item-title" :class="{active: item.isExpend}"><span>{{item.name}}</span><i class="el-icon-caret-bottom" :class="{rotate: item.isExpend}"></i></p>
        <div class="item-text" :ref="'itemText'+item.id" :class="{show: item.isExpend}"><span class="text" :ref="'text'+item.id">{{item.text}}</span></div>
      </div>
    </div>
  </div>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
export default {
  data () {
    return {
      dataList: [
        {id: 1, name: '', text: '', isExpend: false,},
        {id: 2, name: '', text: '', isExpend: false,},
      ]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.dataList[0].name = this.$t('home.fqa1')
      this.dataList[0].text = this.$t('home.fqa11')
      this.dataList[1].name = this.$t('home.fqa2')
      this.dataList[1].text = this.$t('home.fqa21')
    },
    clickItem (item) {
      item.isExpend = !item.isExpend
      if (item.isExpend) {
        this.$nextTick(() => {
          let target = this.$refs[`itemText${item.id}`]
          let height = $(this.$refs[`text${item.id}`]).innerHeight() + 16
          anime({
            targets: target,
            height: height,
            easing: 'linear',
            duration: 300,
          })
        })
      } else {
        this.$nextTick(() => {
          let target = this.$refs[`itemText${item.id}`]
          anime({
            targets: target,
            height: 0,
            easing: 'linear',
            duration: 300,
          })
        })
      }
    },
  },
  watch: {
    language () {
      this.getData()
    },
  },
}
</script>
<style scoped lang="stylus">
.home-list
  width 1200px
  margin 0 auto
  position relative
  z-index 5
  padding-bottom 200px
  .list-container
    width 800px
    .item
      line-height 22px
      border 1px solid #D0CFCE
      border-radius 12px
      padding 16px 28px 6px 28px
      margin-bottom 16px
      .item-title
        font-size $f18
        color $c1
        display flex
        justify-content space-between
        .el-icon-caret-bottom
          font-weight 600
          animation rotate1 .3s ease forwards
        .rotate
          animation rotate2 .3s ease forwards
      .active
        color $c1
      .item-text
        font-size $f16
        color $c2
        height 0
        padding-top 10px
        overflow hidden
        .text
          margin-top 16px
          line-height 24px
@keyframes rotate1
  0%
    transform rotate(180deg)
  100%
    transform rotate(0deg)
@keyframes rotate2
  0%
    transform rotate(0deg)
  100%
    transform rotate(180deg)
@media screen and (max-width: 800px) 
  .home-list
    width 100%
    padding 0 1rem
    padding-bottom 3rem
    .list-container
      width 100%
</style>