<template>
  <div class="miya-home" id="miyaHome">
    <home-bg></home-bg>
    <div class="home-header">
      <img class="logo" src="@assets/images/home/miyaLOGO.svg" @click.stop="$router.push('/home')">
      <div class="headMenu">
        <div class="list">
          <p class="item" v-for="(item, idx) in header" :key="'menu'+idx"  @click.stop="clickMenu(idx)">{{item}}</p>
        </div>        
      </div>
      <miya-lang></miya-lang>
      <div class="btn" v-if="!isLogin" @click.stop="$router.push('/login')">{{$t('home.goLogin')}}</div>
      <div class="btn" v-if="isLogin" @click.stop="$router.push('/layout')">{{$t('home.goChatDraw')}}</div>
    </div>
    <div class="home-content">
      <div class="logo-title">
        <img class="home-title" src="@assets/images/home/home-title.svg">
        <!-- <span class="free">Free Now!!!</span> -->
      </div>
      <div class="title-en">{{$t('home.title1')}}<span class="gpt">{{$t('home.title2')}}</span>{{$t('home.title3')}}</div>
      <div class="btn-go" @click.stop="clickGo()">
        <div class="shadow"></div>
        <span>{{$t('home.btn1')}}</span>
        <div class="right"></div>
      </div>
    </div>
    <div class="home-product">
      <div class="bg bg1"><img class="bgImg" src="@assets/images/home/bg.png"></div>
      <div class="bg bg2"><img class="bgImg" src="@assets/images/home/bg.png"></div>
      <div class="product-container">
        <div class="leftCard">
          <div class="card card1" :class="{active: productId === 'chat'}"><img class="bgImg" src="@assets/images/home/chat.png"></div>
          <div class="card card2" :class="{active: productId === 'draw'}"><img class="bgImg" src="@assets/images/home/draw.png"></div>
        </div>
        <div class="rightText">
          <div class="item" v-if="productId === 'chat'">
            <p class="title1">{{$t('home.product1')}}</p>
            <p class="text">{{$t('home.productText1')}}</p>
          </div>
          <div class="item" v-if="productId === 'draw'">
            <p class="title1">{{$t('home.product2')}}</p>
            <p class="text">{{$t('home.productText2')}}</p>
          </div>
          <div class="tabList">
            <div class="tab" :class="{active: productId === 'chat'}" @click="productId='chat'"></div>
            <div class="tab" :class="{active: productId === 'draw'}" @click="productId='draw'"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-price">
      <p class="title1">{{$t('home.title5')}}</p>
      <p class="title2">{{$t('home.titleText5')}}</p>
      <div class="list">
        <div class="item" v-for="(item, idx) in priceList" :key="'price'+idx">
          <p class="title3">{{item.name}}</p>
          <p class="title4">{{item.text}}</p>
          <p class="price"><span>{{item.price}}</span><span class="unit">{{$t('home.unit')}}</span><span class="label">{{$t('home.label')}}</span></p>
          <p class="listText" v-for="(text, index) in item.list" :key="'listText'+index"><i class="iconfont icon-check"></i><span>{{text}}</span></p>
        </div>
      </div>
    </div>
    <div class="home-faq">
      <p class="title1">{{$t('home.title6')}}</p>
      <p class="title2">{{$t('home.titleText6')}}</p>
    </div>
    <home-list></home-list>
    <home-bottom></home-bottom>
  </div>
</template>
<script>
import homeBg from './homeBg.vue'
import homeList from './homeList.vue'
import homeBottom from './homeBottom.vue'
import miyaLang from '@components/lang.vue'
export default {
  data () {
    return {
      header: [],
      isLogin: false,
      productId: 'chat',
      timer: null,
      priceList: [
        {name: '', text: '', price: 0.01, list: []},
        {name: '', text: '', price: 0.1, list: []},
        {name: '', text: '', price: 0.2, list: []},
      ]
    }
  },
  created () {
    this.getUserInfo()
  },
  mounted () {
    this.getData()
    this.timer = setInterval(() => {
      this.productId = this.productId === 'chat' ? 'draw' : 'chat'
    }, 5000);
  },
  methods: {
    getData () {
      this.header = [this.$t('home.tab1'), this.$t('home.tab2'), this.$t('home.tab3')]
      this.priceList[0].name = this.$t('home.product1')
      this.priceList[1].name = this.$t('home.product1')
      this.priceList[2].name = this.$t('home.product2')
      this.priceList[0].text = this.$t('home.itemText1')
      this.priceList[1].text = this.$t('home.itemText2')
      this.priceList[2].text = this.$t('home.itemText3')
      this.priceList[0].list = [this.$t('home.t1'), this.$t('home.t2'), this.$t('home.t3')]
      this.priceList[1].list = [this.$t('home.t1'), this.$t('home.t2'), this.$t('home.t4'), this.$t('home.t5')]
      this.priceList[2].list = [this.$t('home.t6'), this.$t('home.t7'), this.$t('home.t8'), this.$t('home.t9')]
    },
    clickMenu (num) {
      let top = 0
      if (num === 0) {
        top = $('.home-product').offset().top
      } else if (num === 1) {        
        top = $('.home-price').offset().top
      } else if (num === 2) {        
        top = $('.home-faq').offset().top
      }
      $('html, body').animate({scrollTop: top}, 'slow')
    },
    getUserInfo () {
      this.$get('/Check/GetStatus').then(res => {
        if (res.code === 200) {
          this.isLogin = true
        }
      })
    },
    clickGo () {
      if (this.isLogin) {
        this.$router.push('/layout')
      } else {
        this.$router.push('/login')
      }
    },
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  watch: {
    language () {
      this.getData()
    },
  },
  components: {homeBg, homeList, miyaLang, homeBottom}
}
</script>
<style scoped lang="stylus">
.free
  font-weight bold
  color white
  background-color green
  height 26px
  line-height 26px
  border-radius 10px
  width 85px
  text-align center
  margin-left 10px
.miya-home
  position relative
  min-height 100vh
  .home-header
    position relative
    z-index 5
    padding 12px 32px
    display flex
    justify-content space-between
    .logo
      width 106px
    .headMenu
      margin-left 72px
      flex 1
      .list
        padding-top 5px
        display flex
        .item
          padding 0 12px
          border-radius 50px
          margin-right 60px
          font-size $f16
          line-height 26px
          color $c2
          cursor pointer
          &:hover
            background-color #F6F6F6
            color $c1
    .btn
      border-radius 50px
      background-color $c8
      color $c7
      padding 0 36px
      line-height 36px
      font-size $f16
      cursor pointer
      margin-left 40px
  .home-content, .product-container, .home-price, .home-faq
    width 1200px
    margin 0 auto
  .home-content
    min-height calc(100vh - 60px)
    position relative
    z-index 5
    display flex
    flex-direction column
    justify-content center
    .logo-title
      display flex
      justify-content center
      margin-top -60px
      margin-bottom 26px
    .title-en
      text-align center
      color $c1
      font-size $f20
      line-height 24px
      margin-bottom 106px
      .gpt
        color $c8
        font-weight 600
    .btn-go
      border-radius 100px
      height 56px
      background: linear-gradient(88deg, #FFBF10 0%, #FF6F00 100%)
      font-weight 600
      font-size $f20
      display flex
      justify-content center
      padding 0 36px
      color $c7
      line-height 56px
      margin 0 auto
      position relative
      cursor pointer
      span, .right
        z-index 1
      .right
        margin-left 20px
        width 20px
        height 15px
        margin-top 20px
        background-image url('../../assets/images/home/right.svg')
        background-repeat no-repeat
      .shadow
        position absolute
        width 90%
        height 90%
        left 50%
        top 14px
        background-color $c8
        opacity 0.5
        border-radius 50px
        filter: blur(20px) 
        transform translate3d(-50%,0,0)
        z-index 0
      &:hover
        .right
          animation arrow 0.5s linear infinite
  .home-product
    background: linear-gradient(90deg, #FFF3EB 0%, #FFE9C9 100%)
    padding 108px 0 130px 0
    position relative
    .bg
      position absolute
      .bgImg
        width 100%
    .bg1
      width 30%
      top 0
      left 0
    .bg2
      width 36%
      bottom 0
      right 0
    .product-container
      position relative
      z-indx 5
      display flex
      .leftCard
        width 860px
        height 500px
        margin-right 54px
        position relative
        .card
          position absolute
          border-radius 44px
          box-shadow 0 16px 30px rgba(255,111,0, 0.4)
          z-index 4
          border 1px solid #FFffff
          animation small .3s ease forwards
          overflow hidden
          .bgImg
            width 100%
        .card1
          left 0
        .card2
          right 0
        .active
          box-shadow 0 20px 40px rgba(255,111,0, 0.4)
          z-index 6
          animation big .3s ease forwards
          border 1px solid #FF6F00
      .rightText
        flex 1
        display flex
        position relative
        padding-top 100px
        .item
          margin-bottom 200px
          .title1
            font-size 32px
            margin-bottom 26px
            color $c1
          .text
            font-size 16px
            line-height 24px
            color $c2
        .tabList
          position absolute
          bottom 50px
          display flex
          width 180px
          height 8px
          border-radius 20px
          background-color #FFD2A8
          .tab
            flex 1
          .active
            border-radius 20px
            background-color #FF8F10
  .home-price
    padding 100px 0 120px 0
    .title1
      font-size 36px
      margin-bottom 20px
      color $c1
    .title2
      color $c2
      font-size 20px
      line-height 26px
      margin-bottom 64px
    .list
      display flex
      .item
        flex 1
        margin-right 36px
        border-radius 20px
        border 1px solid #FF6F00
        padding-bottom 24px
        box-shadow 0 6px 12px rgba(255,111,0, 0.16)
        .title3
          background-color #FFF7F0
          padding 14px 24px
          text-align center
          color $c1
          font-weight 600
          font-size 24px
          border-radius 20px 20px 0 0
        .title4
          color $c2
          font-size $f16
          text-align center
          background-color #FFF7F0
          padding-bottom 24px
          border-bottom 1px solid #D0CFCE
        .price
          text-align center
          color $c8
          font-size 48px
          padding 28px 0
          border-bottom 1px solid #D0CFCE
          margin-bottom 24px
          .unit
            margin-left 8px
            font-size $f16
          .label
            padding 0 16px
            position relative
            top -20px
            line-height 22px
            color $c7
            font-size $f16
            background-color $c8
            border-radius 11px 11px 11px 0
        .listText
          margin 0 24px
          padding 16px 0
          border-bottom 1px solid #F6F6F6
          font-size $f16
          line-height $f24
          color $c1
          &:last-child
            border-bottom none
          .iconfont
            font-size $f18
            color #0FC627
            margin-right 10px
        &:last-child
          margin-right 0
          border 1px solid #004CFF
          box-shadow 0 6px 12px rgba(0,76,255, 0.16)
          .title3, .title4
            background-color #F0F5FF
          .price
            color #004CFF
          .label
            background-color #004CFF
  .home-faq
    padding-top 40px
    .title1
      font-size 36px
      margin-bottom 20px
      font-weight 600
      color $c1
    .title2
      color $c2
      font-size 20px
      margin-bottom 64px
  @keyframes big
    0%
      width 714px
      height 443px
      top 27px
    100%
      width 800px
      height 498px
      top 0
  @keyframes small
    0%
      top 0
      width 800px
      height 498px
    100%
      top 27px
      width 714px
      height 443px
  @keyframes arrow
    0%
      background-position -20px 0
    100%
      background-position 20px 0
@media screen and (max-width: 800px) 
  .miya-home
    .home-content, .product-container, .home-price, .home-faq
      width 100%
    .home-header
      padding 0.5rem
      .logo
        display none
      .headMenu
        margin-left 0
        .list
          padding-top 0
          .item
            line-height 1.6rem
            padding 0 0.5rem
            margin-right 0
            font-size 0.65rem
      .btn
        padding 0 0.5rem
        font-size 0.65rem
        margin-left 0.8rem
        line-height 1.6rem
    .home-content
      min-height 60vh
      .logo-title
        width 11rem
        margin-left auto
        margin-right auto
        img
          width 100%
      .title-en
        margin 1rem 1rem 3rem 1rem
        font-size 0.9rem
        line-height 1.6rem
      .btn-go
        font-size 0.9rem
        padding 0 1rem
    .home-product
      padding 3rem 0.5rem 0 1.5rem      
      .bg1
        width 80%
        top -5rem   
      .bg2
        width 60%
      .product-container
        display block
        .leftCard
          width 17.2rem
          height 10rem
          margin-right 0
          .card
            border-radius 1rem
        .rightText
          height 12rem
          padding 2rem 1rem 0 0
          text-align center
          .item
            margin-bottom 5rem
            .title1
              font-size 1.2rem
            .text
              font-size 0.6rem
              line-height 1rem
          .tabList
            left 50%
            bottom 3rem
            width 7rem
            transform translate3d(-50%, 0, 0)
    .home-price
      padding 2rem 0
      .title1
        text-align center
        font-size 1.4rem
      .title2
        text-align center
        font-size 0.8rem
        padding 0 0.5rem
        line-height 1.2rem
        margin-bottom 2rem
      .list
        display block
        .item
          margin 1rem
          &:last-child
            margin 1rem
          .price
            padding 0.8rem 0
            margin-bottom 0.8rem
          .listText
            padding 0.5rem 0
    .home-faq
      padding-top 0
      .title1
        text-align center
        font-size 1.4rem
      .title2
        text-align center
        font-size 0.8rem
        padding 0 0.5rem
        line-height 1.2rem
        margin-bottom 2rem
  @keyframes big
    0%
      width 14.28rem
      height 8.86rem
      top 0.54rem
    100%
      width 16rem
      height 9.96rem
      top 0
  @keyframes small
    0%
      top 0
      width 16rem
      height 9.96rem
    100%
      top 0.54rem
      width 14.28rem
      height 8.86rem
</style>